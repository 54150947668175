/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  max-width: 92%;
}
.desk.southdesk {
  width: 990px;
}
@media only screen and (max-width: 1023px) {
  #head {
    margin-top: 200px;
  }
  .cb-layout2 #head {
    margin-top: 100px;
  }
  .togglenavigation {
    left: 4%;
  }
  .cb-layout2 h1 {
    font-size: 84px;
  }
  #footer {
    font-size: 12px;
  }
  .pic {
    display: none;
  }
  .cb-layout2 .pic.right {
    display: block;
    float: left;
    width: 100%;
    position: relative;
    height: 780px;
    background-position: 50% 20px;
    background-size: 464px auto;
  }
}
@media only screen and (max-width: 1023px) {
  #sponsorswidth {
    margin: 10px 0 80px;
  }
  .cb-layout2 #sponsorswidth {
    margin: 80px 0 0;
  }
  #sponsoren .meta {
    margin: 10px 24px !important;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part,
.area > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 100%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.south h2,
.south .foot {
  margin-right: 3.30188679%;
  margin-left: 3.30188679%;
}
.south .part,
.south > .grid table {
  margin-right: 3.30188679%;
  margin-left: 3.30188679%;
  width: 93.39622642%;
}
.south .tiny {
  width: 43.39622642%;
}
.south > .slim {
  width: 50%;
}
.south > .slim h2,
.south > .slim .foot,
.south > .slim .part,
.south > .slim.grid table {
  margin-right: 6.60377358%;
  margin-left: 6.60377358%;
}
.south > .slim .part,
.south > .slim.grid table {
  width: 86.79245283%;
}
.south > .slim .tiny {
  width: 86.79245283%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
@media only screen and (max-width: 1023px) {
  .main {
    width: 76%;
    margin-left: 12%;
    margin-right: 12%;
  }
  .south {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}
div.base div.unit {
  width: 50%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-medium.css.map */